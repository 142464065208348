import axios from "axios";
import { store, msalInstance } from "../index";
import { setShowLoader } from "../redux/loaderDuck";
import { showErrorModal, showWarningModal } from "../redux/modalAlertDuck";

let apiController = new AbortController();
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  //  timeout: 10000, 
});

api.interceptors.request.use((config) => {
  store.dispatch(setShowLoader(true));
  const account = msalInstance.getAllAccounts()[0];
  console.log("Refresh token...") 
  return msalInstance.acquireTokenSilent({
    scopes: ["7d611a8f-71d3-4bb5-8254-6559b1769661"],
    account: account,
  }).then((response) => {
    const accessToken = response.accessToken;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config; 
  }).catch((error) => {
    console.error('Error acquiring token:', error);
    return Promise.reject(error); 
  });
});

api.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      if (response.data.success === 0) {
        console.log("Show warning");
        store.dispatch(showWarningModal("", response.data.message));
      }
    }
    store.dispatch(setShowLoader(false));
    return response;
  },
  (error) => {
    store.dispatch(setShowLoader(false));
    console.log("error", error)
    if (error.code === 'ERR_NETWORK') {
      window.location.href = "/error";
    }
    else if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
      window.location.href = "/error";
    }
    else if (error.response && error.response.status === 429) {
      store.dispatch(
        showErrorModal(
          "You have reached the limit of requests to the server. Retry in a few minutes.",
          error.response?.data?.error
        )
      );
    }
    else if (error.code === "ERR_CANCELED") {
    }

    else if (error.response && error.response.status === 500) {
      store.dispatch(
        showErrorModal(error.message, error.response?.data?.error)
      );
    }

    else {
      store.dispatch(
        showWarningModal(
          error.response?.data?.type || "Warning",
          error.response?.data?.message || "An error occurred"
        )
      );
    }


    return Promise.reject(error);
  }
);

const restartApiCotroller = () => {
  apiController = new AbortController();
};

export { api, apiController, restartApiCotroller };

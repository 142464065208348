import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useContext } from "react";
import DashboardContext from "../../../layout/DashboardLayout/context";
import {
  Badge,
  Button,
  Divider,
  Input,
  InputNumber,
  Modal,
  Table,
  Checkbox,
  Switch,
  Tooltip,
  notification,
} from "antd";
import Icon, {
  SearchOutlined,
  MinusCircleOutlined,
  FormOutlined,
} from "@ant-design/icons";
import ContractArrowSvg from "../../../assets/svg/ContractArrowSvg";
import CloseIconSvg from "../../../assets/svg/CloseIconSvg";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { getReadingsFromDevice } from "../../../services/historical";
import { getResumeFromDevice } from "../../../services/measurement";
import dayjs from "dayjs";
import ParameterIconTableSvg from "../../../assets/svg/ParameterIconTableSvg";
import ParameterService from "../../../domain/Parameter/ParameterService";
import AnnotationsService from "../../../domain/Annotations/AnnotationsService";
import { dataAnnotationsEventsService } from "../../../events/dataAnnotations.subject.js";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  addReadingToList,
  deleteReadingFromList,
  deleteResumeAndReadingToList,
  getAllList,
} from "../../../redux/readingsResumesDuck";
import NoDataTableSvg from "../../../assets/svg/NoDataTableSvg";
import { generateColor } from "../../utils/DashboardGraphs";

const parameterService = new ParameterService();
const annotationService = new AnnotationsService();

export default function ExpandedParamsCard({ resumeList, readingsList }) {
  const dispatch = useDispatch();
  const { value: context, setValue: setContext } = useContext(DashboardContext);
  const subscriptionDA$ = dataAnnotationsEventsService.getSubject();
  const [isLoading, setLoading] = useState(false);
  const [isDataAvailable, setIsDataAvailable] = useState(true);
  const [graphOptions2, setGraphOptions2] = useState({});
  const chartRef2 = useRef(null);
  const [selectedParamsList, setSelectedParamsList] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [sourcesByParam, setSourcesByParam] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [annotationMode, setAnnotationMode] = useState(false);
  const [sourceToAnnotation, setSourceToAnnotation] = useState(null);
  const [highchartVmRef, setHighchartVmRef] = useState(null);
  const [dates, setDates] = useState(null);
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [showAnnotationListModal, setShowAnnotationListModal] = useState(false);
  const [annotationsList, setAnnotationsList] = useState([]);
  const [isCreationMode, setIsCreationMode] = useState(true);
  const [quality, setQuality] = useState(0);
  const [comment, setComment] = useState("");
  const [title, setTitle] = useState("");
  const [selectedSources, setSelectedSources] = useState([]);
  const [queryParams, setQueryParams] = useState("");
  const [primerRender, setPrimerRender] = useState(true);
  const [annotationListToShow, setAnnotationListToShow] = useState([]);
  const topRef = useRef(null);
  const buttonRefs = useRef({});
  const [colorIndices, setColorIndices] = useState([]);

  const toggleSeriesVisibility = (record, event) => {
    if (chartRef2.current && chartRef2.current.chart) {
      const chart = chartRef2.current.chart;
      chart.series.forEach((series) => {
        if (
          series.name === record.parameterId &&
          series.userOptions.sourceSerialNumber == null
        ) {
          const isVisible = !series.visible;
          series.setVisible(isVisible, false);
          if (event) {
            const buttonElement = event.currentTarget;
            if (buttonElement) {
              if (!isVisible) {
                buttonElement.style.backgroundColor = "";
              } else {
                buttonElement.style.backgroundColor = generateColor(
                  record.parameterId,
                  null
                );
              }
            }
          }
        }
      });
      chart.redraw();
    }
  };

  const filterAnnotationsByDate = (unixDate) => {
    const dateToCompare = dayjs.unix(Math.floor(unixDate / 1000));
    let currentAnnotations = [];

    setAnnotationsList((currentAnnotationList) => {
      currentAnnotations = currentAnnotationList;
      return currentAnnotationList;
    });

    return currentAnnotations.filter((annotation) => {
      const startDate = dayjs(annotation.startDate);
      const endDate = dayjs(annotation.endDate);
      return (
        dateToCompare.isAfter(startDate) && dateToCompare.isBefore(endDate)
      );
    });
  };

  const columnsExpanded = useMemo(
    () => [
      {
        title: () => {
          return "Action";
        },
        render: (_, record) => {
          const isDisabled = false;
          return (
            <button
              ref={(el) => (buttonRefs.current[record.parameterId] = el)}
              style={{
                backgroundColor: isDisabled
                  ? "transparent"
                  : generateColor(record.parameterId, null),
                borderRadius: "13px",
                paddingBottom: "5px",
              }}
              className={`rounded-lg pb-1.5 ${
                isDisabled ? "bg-gray-400" : "bg-blue-500"
              }`}
              onClick={(event) => {
                toggleSeriesVisibility(record, event);
              }}
              disabled={isDisabled}
            >
              <Tooltip title="Toggle">
                <Icon component={ParameterIconTableSvg} />
              </Tooltip>
            </button>
          );
        },
        key: "key",
        width: 100,
      },
      {
        title: "Infra.",
        dataIndex: "infrastructureName",
        key: "infrastructureName",
        width: 200,
      },
      {
        title: "Param.",
        dataIndex: "parameter",
        key: "parameter",
        width: 200,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        width: 80,
      },
      {
        title: "Other Sources",
        render: (_, record) => (
          <div className="flex column">
            {/* <span>{`${record.sourceName}`}</span> */}
            <a
              style={{
                textDecoration: "underline",
                color: "#078CB3",
                cursor: "pointer",
              }}
              onClick={(event) => {
                event.preventDefault();
                handleShowOtherSources(record);
                // setSelectedInfraToShow(record.infrastructureId);
                // setshowOtherSourcesModal(true);
              }}
            >
              See other sources
            </a>
          </div>
        ),
        width: 160,
      },
      {
        title: "Units",
        dataIndex: "unit",
        key: "unit",
        width: 80,
      },
      {
        title: "Last Reading",
        render: (_, record) => (
          <>
            <span>{`${record.currentValue}`}</span>
          </>
        ),
        width: 90,
      },
      {
        title: "Last Collected",
        render: (_, record) => (
          <span>
            {record.lastCollected && record.lastCollected != "N/A"
              ? `${dayjs(record.lastCollected).format("YYYY/MM/DD hh:mm A")}`
              : "No data available"}
          </span>
        ),
        width: 180,
      },
      {
        title: "Current Utilization",
        render: (_, record) => (
          <div className="flex">
            <span className="mr-1">{`${record.usage}`}</span>
            <Badge color={record.color} />
          </div>
        ),
        width: 100,
      },
      {
        title: "Max.",
        render: (_, record) => <span>{`${record.maximun}`}</span>,
        width: 90,
      },
      {
        title: "Min.",
        render: (_, record) => <span>{`${record.minimum}`}</span>,
        width: 90,
      },
      {
        title: "Sum",
        render: (_, record) => <span>{record.source ? record.sum : "-"}</span>,
        width: 100,
      },
      {
        title: "Average",
        render: (_, record) => <span>{`${record.average}`}</span>,
        width: 90,
      },
      {
        title: "Critical High High",
        render: (_, record) => <span>{`${record.criticalHhigh}`}</span>,
        width: 90,
      },
      {
        title: "Critical High",
        render: (_, record) => <span>{`${record.criticalHigh}`}</span>,
        width: 90,
      },
      {
        title: "Critical Low",
        render: (_, record) => <span>{`${record.criticalLow}`}</span>,
        width: 90,
      },
      {
        title: "Critical Low Low",
        render: (_, record) => <span>{`${record.criticalLlow}`}</span>,
        width: 90,
      },
      {
        title: "",
        key: "AditionAction",
        render: (_, record) => (
          <Tooltip title="Remove">
            <Icon
              component={MinusCircleOutlined}
              style={{ color: "#F46A6A", fontSize: "20px", cursor: "pointer" }}
              onClick={() => {
                setSourcesByParam((oldValue) => {
                  return oldValue.filter((sp) => sp.id != record.parameterId);
                });
                setSelectedParamsList((selectedParams) => {
                  let copy = [...selectedParams];
                  const paramToDelete = copy.find((param) => {
                    return param.parameterId == record.parameterId;
                  });
                  paramToDelete.sourceList.map((source) => {
                    if (source.sourceId) {
                      dispatch(
                        deleteReadingFromList(
                          paramToDelete.parameterId,
                          source.serialNumber
                        )
                      );
                    } else {
                      dispatch(
                        deleteResumeAndReadingToList(
                          paramToDelete.parameterId,
                          null
                        )
                      );
                    }
                  });
                  copy = copy.filter(
                    (param) => param.parameterId != record.parameterId
                  );
                  return copy;
                });
                // dispatch(
                //   deleteResumeAndReadingToList(record.parameterId, null)
                // );
                setContext((currentContext) => {
                  const newSelectedSensors =
                    currentContext.selectedSensors.filter((sensor) => {
                      return sensor.measurementTargetId != record.parameterId;
                    });
                  return {
                    ...currentContext,
                    isExpandedParamsCard: newSelectedSensors != 0,
                    selectedSensors: newSelectedSensors,
                  };
                });
              }}
            />
          </Tooltip>
        ),
        width: 60,
      },
    ],
    [dataTable, queryParams]
  );

  const columnsAnnotationsModal = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "title",
        key: "title",
      },
      {
        title: (
          <>
            <span>Infra</span>
            <br />
            <span>Param</span>
            <br />
            <span>Source</span>
          </>
        ),
        key: "",
        render: (_, record) => (
          <>
            <span>{`${record.infraName}`}</span>
            <br />
            <span>{record.paramName}</span>
            <br />
            <span>{record.sourceName}</span>
          </>
        ),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
      },
      {
        title: "Quality",
        dataIndex: "quality",
        key: "quality",
      },
      {
        title: "Start date",
        key: "startDate",
        render: (_, record) => (
          <span>{`${dayjs(record.startDate).format(
            "YYYY/MM/DD [at] hh:mm a"
          )}`}</span>
        ),
      },
      {
        title: "End date",
        key: "endDate",
        render: (_, record) => (
          <span>{`${dayjs(record.endDate).format(
            "YYYY/MM/DD [at] hh:mm a"
          )}`}</span>
        ),
      },
      {
        title: "Created by",
        dataIndex: "createdBy",
        key: "createdBy",
        width: 200,
        render: (_, record) => (
          <div className="flex column">
            <span>{`${record.createdBy}`}</span>
            <span style={{ color: "#74788D", fontSize: "10px" }}>{`${dayjs(
              record.createdAt
            ).format("YYYY/MM/DD [at] hh:mm a")}`}</span>
          </div>
        ),
      },
    ],
    []
  );

  async function handleSaveAnnotation() {
    const data = {
      serialNumber: sourceToAnnotation.serialNumber,
      startDate: dayjs(dates[0]).format("YYYY-MM-DD HH:mm:ss"),
      endDate: dayjs(dates[1]).format("YYYY-MM-DD HH:mm:ss"),
      quality,
      comment,
      title,
    };
    const res = await annotationService.postAnnotations(data);
    if (res) {
      notification.success({
        message: "Successful",
        description: "Annotation created successfully",
      });
      handleGetAnnotations(
        sourceToAnnotation.sourceId,
        sourceToAnnotation.parameterId,
        sourceToAnnotation.serialNumber
      );
    }
  }

  const hexToRgba = (hex, alpha = 0.3) => {
    if (hex.startsWith("#")) {
      hex = hex.slice(1);
    }

    if (hex.length === 3) {
      hex = hex
        .split("")
        .map((char) => char + char)
        .join("");
    }

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };

  async function handleGetAnnotations(sourceId, parameterId, serialNumber) {
    if (chartRef2.current && chartRef2.current.chart) {
      const [dateFrom, dataTo] = context.selectedDate;
      const response = await annotationService.getAnnotationsBySourceId(
        sourceId,
        dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
        dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
      );
      if (response) {
        setAnnotationsList((prev) => {
          const newAnnotationList = [...prev, ...response].filter(
            (objeto, indice, self) =>
              indice ===
              self.findIndex((o) => o.annotationId === objeto.annotationId)
          );

          return newAnnotationList;
        });
        response.forEach((annotation) => {
          const plotband = {
            from: new Date(annotation.startDate).getTime(),
            to: new Date(annotation.endDate).getTime(),
            color: hexToRgba(generateColor(parameterId, serialNumber)),
            id: annotation.annotationId,
            events: {},
          };
          dataAnnotationsEventsService.setSubject({
            type: "addPlotBand",
            data: plotband,
          });
        });
      }
    }
  }

  async function getDataResumeFromParam(param) {
    const [dateFrom, dataTo] = context.selectedDate;
    if (!dateFrom || !dataTo) return;
    const resume = await getResumeFromDevice(
      param.infrastructureId,
      param.parameterId,
      param.mainSerialNumber,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
    );
    return resume;
  }

  async function getDataReadingFromSource(param, source) {
    const [dateFrom, dataTo] = context.selectedDate;
    if (!dateFrom || !dataTo) return;
    const reading = await getReadingsFromDevice(
      param.parameterId,
      source.serialNumber,
      dayjs(dateFrom).format("YYYY-MM-DD HH:MM:ss"),
      dayjs(dataTo).format("YYYY-MM-DD HH:mm:ss")
    );
    return reading;
  }

  async function fetchDataToTable() {
    Promise.all(
      selectedParamsList.map(async (param) => {
        const resume = await getDataResumeFromParam(param);
        return resume;
      })
    ).then((resumeArray) => {
      setLoading(false);
      buildDataToTable(resumeArray);
    });
  }

  async function fetchDataToGraph() {
    const [dateFrom, dataTo] = context.selectedDate;
    if (!dateFrom || !dataTo) return;

    setLoading(true);
    Promise.all(
      selectedParamsList.map((param) => {
        return Promise.all(
          param.sourceList.map((source) => {
            return getDataReadingFromSource(param, source);
          })
        );
      })
    ).then((dataArray) => {
      setLoading(false);
      const flattenedData = dataArray.flat();
      buildDataToGraph(flattenedData).then(() => {
        // handleGetAnnotations([
        //   ...new Set(data.map((series) => series.serialNumberMeasurementTool)),
        // ]);
      });
      // setContext({ ...context, showParamsCard: true });
      // setContext({
      //   ...context,
      //   isExpandedParamsCard: !context.isExpandedParamsCard,
      // });
      // setTimeout(() => {
      //   setContext((current) => {
      //     return {
      //       ...current,
      //       isExpandedParamsCard: !current.isExpandedParamsCard,
      //     };
      //   });
      //   setLoading(false);
      // }, 100);
    });
  }

  function buildDataToTable(dataArray) {
    const colors = Highcharts.getOptions().colors;
    if (!colors) return;

    const seriesData = dataArray.map((resume, index) => {
      const colors = Highcharts.getOptions().colors;
      if (!colors) return;

      const seriesData = dataArray
        .map((resume, index) => {
          if (resume) {
            const colorIndex = index;
            setColorIndices((prevColorIndices) => {
              const newColorIndices = [...prevColorIndices];
              newColorIndices[resume.parameterId] = colorIndex;
              return newColorIndices;
            });
            return {
              parameter: resume.parameterName,
              parameterId: resume.parameterId,
              type: resume.sourceType,
              unit: resume.unit,
              criticalHigh: resume.criticalHigh,
              criticalHhigh: resume.criticalHhigh,
              criticalLow: resume.criticalLow,
              criticalLlow: resume.criticalLlow,
              currentValue: resume.currentValue,
              lastCollected: resume.lastCollected,
              usage: resume.usage,
              sourceName: resume.sourceName,
              maximun: resume.max,
              minimum: resume.min,
              average: resume.average,
              color: getColor(
                resume.currentValue,
                resume.criticalHigh,
                resume.criticalHhigh,
                resume.criticalLow,
                resume.criticalLlow
              ),
              colorLine: colors[index],
              // measurementTargetId: data.measurementTargetId,
              sum: resume.sum,
              source: resume.sourceName,
              infrastructureId: resume.infrastructureId,
              key: `${resume.infrastructureId}-${resume.parameterId}`,
              infrastructureName: resume.infrastructureName,
            };
          } else {
            return null;
          }
        })
        .filter((item) => {
          return item !== null;
        });
      setDataTable(seriesData);
    });
  }

  function getColor(
    currentValue,
    criticalHigh,
    criticalHhigh,
    criticalLow,
    criticalLlow
  ) {
    if (criticalHigh >= currentValue && currentValue <= criticalHhigh) {
      return "orange";
    }
    if (criticalHhigh <= currentValue) {
      return "red";
    }
    if (criticalLow >= currentValue && currentValue <= criticalLlow) {
      return "orange";
    }
    if (currentValue <= criticalLlow) {
      return "red";
    }
    if (criticalLow <= currentValue && currentValue <= criticalHigh) {
      return "green";
    }
    return "green";
  }

  const getOtherSourcesByInfra = async (paramId) => {
    const res = await parameterService.getParameter(paramId);
    setSourcesByParam((oldValue) => {
      const indexFinded = oldValue.findIndex((param) => param.id == paramId);
      if (indexFinded >= 0) {
        let copy = [...oldValue];

        copy[indexFinded] = {
          id: paramId,
          sources: [
            { name: "Recommended", sourceId: null, serialNumber: null },
            ...res.sources,
          ],
        };
        return copy;
      }
      return [
        ...oldValue,
        {
          id: paramId,
          sources: [
            { name: "Recommended", sourceId: null, serialNumber: null },
            ...res.sources,
          ],
        },
      ];
    });
  };

  const findRecord = useCallback(
    (paramId, serialNumber) => {
      return readingsList.find((rl) => {
        if (rl) {
          return (
            rl.originalParameterId == paramId &&
            rl.originalSourceSerialNumber == serialNumber
          );
        } else {
          return false;
        }
      });
    },
    [readingsList]
  );

  const handleShowOtherSources = async (record) => {
    await getOtherSourcesByInfra(record.parameterId);
    setExpandedRowKeys((oldValue) => {
      return [...oldValue, record.key];
    });
  };

  const expandedRowRender = (record) => {
    let paramSources = sourcesByParam.find(
      (param) => param.id == record.parameterId
    );

    const setColor = (record, record2, index) => {
      if (index == 0) {
        const button = buttonRefs.current[record.parameterId];
        if (button) {
          button.style.backgroundColor = generateColor(
            record.parameterId,
            null
          );
          button.disabled = false;
        }
      } else {
        const button = buttonRefs.current[record2.sourceId];
        if (button) {
          button.style.backgroundColor = generateColor(
            record.parameterId,
            record2.serialNumber
          );
          button.disabled = false;
        }
      }
    };

    const clearColor = (record, record2, index) => {
      if (index == 0) {
        const button = buttonRefs.current[record.parameterId];
        if (button) {
          button.style.backgroundColor = "transparent";
          button.disabled = true;
        }
      } else {
        const button = buttonRefs.current[record2.sourceId];
        if (button) {
          button.style.backgroundColor = "transparent";
          button.disabled = true;
        }
      }
    };

    const columnsExpand = [
      {
        title: "",
        render: (_, record2, index) => {
          if (index > 0) {
            const isDisabled = false;
            return (
              <button
                ref={(el) => (buttonRefs.current[record2.sourceId] = el)}
                style={{
                  backgroundColor: isDisabled ? "transparent" : "",
                  borderRadius: "13px",
                  paddingBottom: "5px",
                }}
                className={`rounded-lg pb-1.5 ${
                  isDisabled ? "bg-gray-400" : "bg-blue-500"
                }`}
                disabled={isDisabled}
              >
                <Tooltip title="Toggle">
                  <Icon component={ParameterIconTableSvg} />
                </Tooltip>
              </button>
            );
          }
          return null;
        },
        key: "space1",
        width: 200,
      },
      {
        title: "",
        key: "space1",
        width: 200,
      },
      {
        title: "",
        key: "space1",
        width: 80,
      },
      {
        title: "",
        key: "sourceId",
        render: (_, record2, index) => (
          <div className="flex">
            <Checkbox
              defaultChecked={context.selectedSensors.find(
                (sensor) =>
                  sensor.sourceId == record2.sourceId &&
                  sensor.measurementTargetId == record.parameterId
              )}
              onChange={(e) => {
                if (!record2.serialNumber) {
                  console.log(
                    "buttonRefs",
                    buttonRefs.current[record.parameterId].style
                  );
                  toggleSeriesVisibility(
                    record,
                    buttonRefs.current[record.parameterId]
                  );
                  if (e.target.checked) {
                    buttonRefs.current[
                      record.parameterId
                    ].style.backgroundColor = generateColor(
                      record.parameterId,
                      null
                    );
                  } else {
                    buttonRefs.current[
                      record.parameterId
                    ].style.backgroundColor = "";
                  }
                  return;
                }
                if (e.target.checked) {
                  const [dateFrom, dataTo] = context.selectedDate;
                  dispatch(
                    addReadingToList(
                      record.parameterId,
                      record2.serialNumber,
                      dateFrom,
                      dataTo
                    )
                  );
                  setSelectedParamsList((selectedParams) => {
                    let copy = [...selectedParams];
                    const indexFinded = copy.findIndex(
                      (param) => param.parameterId == record.parameterId
                    );
                    if (indexFinded >= 0) {
                      copy[indexFinded].sourceList = [
                        ...copy[indexFinded].sourceList,
                        {
                          sourceId: record2.sourceId,
                          serialNumber: record2.serialNumber,
                        },
                      ];
                    }
                    return copy;
                  });
                  setColor(record, record2, index);
                } else {
                  if (
                    context.selectedSensors.indexOf(
                      (record) =>
                        record.serialNumberMeasurementTool ==
                        record2.serialNumber
                    ) < 0
                  ) {
                    dispatch(
                      deleteReadingFromList(
                        record.parameterId,
                        record2.serialNumber
                      )
                    );
                  }
                  setSelectedParamsList((selectedParams) => {
                    let copy = [...selectedParams];
                    const indexFinded = copy.findIndex(
                      (param) => param.parameterId == record.parameterId
                    );
                    copy[indexFinded].sourceList = copy[
                      indexFinded
                    ].sourceList.filter(
                      (sl) => sl.serialNumber !== record2.serialNumber
                    );
                    return copy;
                  });
                  clearColor(record, record2, index);
                }
              }}
            >
              {record2.name}
            </Checkbox>
            {record2.sourceId &&
              findRecord(paramSources.id, record2.serialNumber) && (
                <Tooltip title="Add annotation">
                  <FormOutlined
                    className="ml-2"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      console.log("Record to annotations", record);
                      setSourceToAnnotation({
                        ...record2,
                        parameterId: record.parameterId,
                      });
                      setAnnotationMode(true);
                      scrollToTop();
                    }}
                  />
                </Tooltip>
              )}
          </div>
        ),
        width: 160,
      },
      {
        title: "",
        key: "space1",
        width: 80,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 100,
      },
      {
        title: "",
        key: "space1",
        width: 180,
      },
      {
        title: "",
        key: "space1",
        width: 100,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 90,
      },
      {
        title: "",
        key: "space1",
        width: 60,
      },
    ];
    if (paramSources) {
      return (
        <Table
          columns={columnsExpand}
          dataSource={paramSources.sources}
          pagination={false}
        />
      );
    }
  };

  async function buildDataToGraph(dataArray) {
    setGraphOptions2({});
    const yAxis = [];

    dataArray.forEach((item) => {
      if (item === undefined) return;
      const key = `${item.valueUnit}-${item.sensorType}`;
      if (!yAxis.find(({ uniqueKey }) => uniqueKey === key)) {
        yAxis.push({
          uniqueKey: key,
          valueUnit: item.valueUnit,
          sensorType: item.sensorType,
          labels: {
            format: `{value}${item.valueUnit}`,
          },
          title: {
            text: `${item.sensorType}`,
          },
        });
      }
    });

    yAxis.sort((a, b) => {
      if (a.valueUnit < b.valueUnit) return -1;
      if (a.valueUnit > b.valueUnit) return 1;
      return 0;
    });

    const checkGrouping = () => {
      const positions = {};

      yAxis.forEach((entry, index) => {
        if (!positions[entry.valueUnit]) {
          positions[entry.valueUnit] = [];
        }
        positions[entry.valueUnit].push(index);
      });

      let allGrouped = true;

      for (const valueUnit in positions) {
        const posArray = positions[valueUnit];
        for (let i = 1; i < posArray.length; i++) {
          if (posArray[i] !== posArray[i - 1] + 1) {
            allGrouped = false;
            break;
          }
        }
      }

      if (allGrouped) {
      }
    };

    checkGrouping();
    let seriesData = [];
    dataArray.forEach((data, index) => {
      if (data === undefined) return;
      const measurementHistorialValues = data.measurementToolHistoricalValues;
      if (measurementHistorialValues) {
        const keys = Object.keys(measurementHistorialValues);
        const dataSeries = keys
          .map((item) => {
            return {
              x: parseFloat(item),
              y: measurementHistorialValues[item].value,
              origen: measurementHistorialValues[item].serialNumber,
            };
          })
          .filter((item) => {
            return item.y != null;
          })
          .sort((a, b) => {
            return a.x - b.x;
          });
        const read = {
          keyname: data.measurementTargetName,
          name: data.measurementTargetId,
          paramId: data.originalParameterId,
          paramId: data.originalParameterId,
          valueUnit: data.valueUnit,
          sensorType: data.sensorType,
          isForecast: false,
          sourceSerialNumber: data.originalSourceSerialNumber,
          sourceId: data.measurementToolId,
          yAxis: yAxis.findIndex(
            ({ valueUnit }) => valueUnit === data.valueUnit
          ),
          data: dataSeries,
          color: generateColor(
            data.originalParameterId,
            data.originalSourceSerialNumber
          ),
          color: generateColor(
            data.originalParameterId,
            data.originalSourceSerialNumber
          ),
        };
        seriesData.push(read);
      }
      if (data.measurementToolForecastValues) {
        data.measurementToolForecastValues?.forEach((forecast, indexData) => {
          const colors = Highcharts.getOptions().colors;
          if (!colors) return;
          const foreData = {
            keyname: data.measurementTargetName,
            name: data.measurementTargetId,
            sourceName: data.serialNumberTool,
            valueUnit: data.valueUnit,
            sensorType: data.sensorType,
            sourceId: data.measurementToolId,
            yAxis: yAxis.findIndex(
              ({ valueUnit }) => valueUnit === data.valueUnit
            ),
            showInLegend: false,
            color: generateColor(
              data.originalParameterId,
              data.originalSourceSerialNumber
            ),
            color: generateColor(
              data.originalParameterId,
              data.originalSourceSerialNumber
            ),
            dashStyle: "Dash",
            isForecast: true,
            isPluviometer: data.measurementToolForecastValues.length > 1,
            forecastIndex: indexData,
            data: forecast
              ? Object.keys(forecast)
                  .map((item) => {
                    // return [parseFloat(item), forecast[item]];
                    return {
                      x: parseFloat(item),
                      y: forecast[item],
                      origen: data.serialNumberTool,
                    };
                  })
                  .filter((item) => {
                    return item.y != null;
                  })
                  .sort((a, b) => {
                    return a.x - b.x;
                  })
              : [],
          };
          seriesData.push(foreData);
        });
      }
    });

    if (seriesData.length <= 0) {
      setIsDataAvailable(false);
    } else {
      setIsDataAvailable(true);
    }

    const [dateFrom, dataTo] = context.selectedDate;
    const localOffset = new Date().getTimezoneOffset() * 60 * 1000;
    setGraphOptions2({
      title: {
        text: "",
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: "line",
        zoomType: "x",
        panKey: "shift",
        height: null,
        plotBorderWidth: 1,
        backgroundColor: "#FFFFFF",
        events: {
          load: function () {
            const vm = this;
            setHighchartVmRef(this);
            subscriptionDA$.subscribe((event) => {
              if (event.type == "handleGetAnnotation") {
                handleGetAnnotations(
                  event.data.sourceId,
                  event.data.parameterId,
                  event.data.serialNumber
                );
              }
              if (event.type == "addPlotBand" && vm.xAxis && vm.xAxis[0]) {
                vm.xAxis[0].addPlotBand(event.data);
                if (vm && vm.xAxis) {
                  vm.xAxis[0] && vm.xAxis[0].setExtremes(null, null);
                  vm.xAxis[1] && vm.xAxis[1].setExtremes(null, null);
                }
              }
            });
          },
          click: (event) => {
            setAnnotationListToShow(
              filterAnnotationsByDate(event.xAxis[0].value)
            );
            setShowAnnotationListModal(true);
          },
          selection: handleRangeSelected,
        },
      },
      legend: {
        backgroundColor: "#FFFFFF",
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        plotBands: [
          {
            from: new Date() - localOffset,
            to: new Date(dataTo),
            color: "#EFFFFF",
          },
        ],
        min: new Date(dateFrom).getTime() - localOffset,
        max: new Date(dataTo).getTime() - localOffset,
        ordinal: false,
      },
      yAxis: yAxis,
      plotOptions: {
        series: {
          connectNulls: true,
          label: {
            connectorAllowed: false,
          },
          turboThreshold: 0,
          cursor: annotationMode ? "pointer" : "undefined",
        },
      },
      navigator: {
        enabled: true,
        adaptToUpdatedData: true,
        stickToMax: false,
      },
      tooltip: {
        formatter: function () {
          const vm = this;
          let text = `<span>${dayjs(vm.points[0].x + localOffset).format(
            "YYYY-MM-DD hh:mm:ss A"
          )}</span><br/><br/>`;
          vm.points.forEach((point) => {
            const series = point.series;
            const userOptions = series.userOptions;
            const symbol =
              userOptions.isForecast && userOptions.isPluviometer
                ? userOptions.forecastIndex == 1
                  ? "◆"
                  : userOptions.forecastIndex == 2
                  ? "■"
                  : "▲"
                : "●";
            if (userOptions.isForecast) {
              text += `<span style="color:${series.color}">${symbol} Iteration ${userOptions.index}</span> - ${userOptions.name}: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Source: <b>${point.point.origen}</b></span><br/>`;
            } else {
              text += `<span style="color:${series.color}">${symbol} ${series.userOptions.keyname}</span>: <b>${point.y} ${series.userOptions.valueUnit}</b><br/><span> Source: <b>${point.point.origen}</b></span><br/>`;
            }
          });
          return [text];
        },
        valueDecimals: 2,
        shared: true,
        xDateFormat: "%Y-%m-%d %H:%M:%S",
      },
      rangeSelector: {
        buttonTheme: {
          style: {
            display: "none",
          },
        },
        enabled: false,
        dropdown: "always",
        inputDateFormat: "%Y/%m/%d %I:%M %p",
        selected: 1,
      },
      series: seriesData,
    });
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
    }
  }

  function handleRangeSelected(event) {
    setAnnotationMode((current) => {
      if (current) {
        event.preventDefault();
        setDates([
          Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].min),
          Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].max),
        ]);
        setShowAnnotationModal(true);
      }
      return false;
    });
  }

  const paramsToShowInTable = useCallback(() => {
    if (queryParams != "") {
      return dataTable.filter((md) => {
        return md.parameter.includes(queryParams);
      });
    } else {
      return dataTable;
    }
  }, [queryParams, dataTable]);

  function getRangeString(numbers) {
    if (numbers.length === 0) {
      return "";
    }
    const minor = Math.min(...numbers);
    const major = Math.max(...numbers);
    return [minor, major];
  }

  function checkQualityForNewAnnotation(annotations, newStartDate, newEndDate) {
    // Convertir las fechas de entrada a objetos Date para facilitar la comparación
    const newStart = new Date(newStartDate);
    const newEnd = new Date(newEndDate);

    let qualities = [];
    let exceedMaxLimits = true;
    let exceedMinLimits = true;

    for (const annotation of annotations) {
      const startDate = new Date(annotation.startDate);
      const endDate = new Date(annotation.endDate);

      // Verificar si la nueva anotación se solapa con la anotación actual
      if (newStart <= endDate && newEnd >= startDate) {
        qualities.push(annotation.quality);

        // Verificar si la nueva anotación abarca un rango más amplio
        if (newStart >= startDate) {
          exceedMinLimits = false;
        }
        if (newEnd <= endDate) {
          exceedMaxLimits = false;
        }
      }
    }

    if (qualities.length > 0 && (exceedMaxLimits || exceedMinLimits)) {
      const minQuality = Math.min(...qualities);
      return `${minQuality} - 10`;
    }

    if (qualities.length === 0) {
      return "10"; // No hay solapamiento, retornar "10"
    } else if (qualities.length === 1) {
      return `${qualities[0]}`; // Solo un solapamiento, retornar el único valor como string
    } else {
      const minQuality = Math.min(...qualities);
      const maxQuality = Math.max(...qualities);
      return `${minQuality} - ${maxQuality}`; // Múltiples solapamientos, retornar como "min - max"
    }
  }

  const scrollToTop = () => {
    // Utiliza scrollIntoView para hacer el desplazamiento
    topRef.current.scrollIntoView({ behavior: "smooth" });
  };

  function getNewElements(newArray, oldArray) {
    return newArray.filter(
      (item) =>
        !oldArray.some((oldItem) => oldItem.parameterId == item.parameterId)
    );
  }

  useEffect(() => {
    buildDataToGraph([]);
    setTimeout(() => {
      setPrimerRender(false);
    }, 400);
  }, []);

  useEffect(() => {
    if (highchartVmRef && highchartVmRef.xAxis) {
      highchartVmRef.xAxis[1] &&
        highchartVmRef.xAxis[1].setExtremes(null, null);
      highchartVmRef.xAxis[0] &&
        highchartVmRef.xAxis[0].setExtremes(null, null);
    }
  }, [highchartVmRef]);

  useEffect(() => {
    if (context.selectedSensors.length > 0) {
      const selectedSs = context.selectedSensors.map((sensor) => {
        return {
          paramName: sensor.measurementTargetName,
          parameterId: sensor.measurementTargetId,
          infrastructureId: sensor.infrastructureId,
          mainSerialNumber: sensor.serialNumberMeasurementTool,
          sourceList: [
            {
              sourceId: sensor.sourceId,
              serialNumber: sensor.serialNumberMeasurementTool,
            },
          ],
        };
      });
      setSelectedParamsList((prev) => {
        return [...prev, ...getNewElements(selectedSs, prev)];
      });
    }
  }, [context.selectedSensors, context.selectedDate]);

  useEffect(() => {
    if (!primerRender) {
      setSelectedParamsList([]);
    }
  }, [context.selectedLocation]);

  useEffect(() => {
    setSelectedSources(() => {
      return selectedParamsList.reduce(
        (acum, param) => [
          ...acum,
          ...param.sourceList.map((source) => {
            return {
              parameterId: param.parameterId,
              sourceId: source.sourceId,
              serialNumber: source.serialNumber,
            };
          }),
        ],
        []
      );
    });
  }, [selectedParamsList]);

  useEffect(() => {}, [selectedSources]);

  useEffect(() => {
    if (resumeList && resumeList.length > 0) {
      const seen = new Set();
      const data = resumeList.filter((item) => {
        if (item) {
          const tuple = JSON.stringify([item.parameterId]);
          // Si el set ya contiene la tupla, este objeto ya fue visto
          if (seen.has(tuple)) {
            return false;
          }
          // Si no, agrega la tupla al set y devuelve verdadero para mantener este objeto
          seen.add(tuple);
        }
        return (
          item !== undefined &&
          selectedParamsList.find((param) => {
            return param.parameterId == item.parameterId;
          })
        );
      });
      buildDataToTable(data);
    } else {
      buildDataToTable([]);
    }
  }, [resumeList, selectedParamsList]);

  useEffect(() => {
    if (readingsList && readingsList.length > 0) {
      const seen = new Set();
      const data = readingsList.filter((item) => {
        if (item) {
          const tuple = JSON.stringify([
            item.originalParameterId,
            item.originalSourceSerialNumber,
            item.measurementToolId,
          ]);
          // Si el set ya contiene la tupla, este objeto ya fue visto
          if (seen.has(tuple)) {
            return false;
          }
          // Si no, agrega la tupla al set y devuelve verdadero para mantener este objeto
          seen.add(tuple);
        }
        return (
          item !== undefined &&
          selectedSources.find(
            (source) =>
              source.serialNumber == item.originalSourceSerialNumber &&
              source.parameterId == item.originalParameterId
          )
        );
      });
      buildDataToGraph(data);
    }
    setAnnotationsList([]);
    let currentSelectedSources = [];
    setSelectedSources((sl) => {
      if (sl.length > 0) {
        sl.forEach((ss) => {
          if (ss.sourceId) {
            dataAnnotationsEventsService.setSubject({
              type: "handleGetAnnotation",
              data: ss,
            });
          }
        });
        // const cont = document.querySelector("rect.highcharts-background");
        // cont && cont.classList.add("cursor-pointer");
      }
      return sl;
    });
    // else {
    //   const cont = document.querySelector("rect.highcharts-background");
    //   cont && cont.classList.remove("cursor-pointer");
    // }
  }, [readingsList]);

  useEffect(() => {
    const otherSourcesSelected = selectedSources.filter((source) => {
      return context.selectedSensors.find(
        (sensor) => sensor.serialNumberMeasurementTool !== source.serialNumber
      );
    });
    const [dateFrom, dataTo] = context.selectedDate;
    dispatch(
      getAllList(
        context.selectedSensors,
        context.selectedDate[0],
        context.selectedDate[1]
      )
    ).then(() => {
      otherSourcesSelected.forEach((os) => {
        dispatch(
          addReadingToList(os.parameterId, os.serialNumber, dateFrom, dataTo)
        );
      });
    });
  }, [context.selectedDate]);

  return (
    <>
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          width: "100%",
          height: "calc(100vh - 55px)",
          backgroundColor: "white",
          zIndex: "9",
          padding: "20px",
          overflow: "auto",
        }}
        className={
          isLoading || !context.isExpandedParamsCard || !context.showParamsCard
            ? "hidden"
            : ""
        }
      >
        <div
          className="flex items-center"
          style={{ height: "30px" }}
          ref={topRef}
        >
          <span className="mr-2">Dates selected</span>
          <div
            className="flex p-1"
            style={{
              backgroundColor: "#F7F9FC",
            }}
          >
            <span>{`${dayjs(context.selectedDate[0]).format(
              "YYYY/MM/DD - hh:mm a"
            )}`}</span>
            <div
              className="mx-2"
              style={{
                width: "1px",
                height: "100%",
                backgroundColor: "#E6E6E6",
              }}
            />
            <span>{`${dayjs(context.selectedDate[1]).format(
              "YYYY/MM/DD - hh:mm a"
            )}`}</span>
          </div>
        </div>
        <div
          className="flex justify-center items-center"
          style={{ position: "absolute", top: "10px", right: "10px" }}
        >
          <Icon
            component={ContractArrowSvg}
            className="mr-2 cursor-pointer"
            onClick={() => {
              setContext({ ...context, isExpandedParamsCard: false });
            }}
          />
          <Icon
            component={CloseIconSvg}
            className="cursor-pointer"
            onClick={() => {
              setContext({ ...context, showParamsCard: false });
            }}
          />
        </div>
        <div className="flex column">
          <div className="flex">
            <div
              className="self-center"
              style={{
                height: "calc(100vh - 315px)",
                width: "-webkit-fill-available",
              }}
            >
              {isDataAvailable &&
              selectedSources.length > 0 &&
              readingsList.length > 0 ? (
                <HighchartsReact
                  ref={chartRef2}
                  highcharts={Highcharts}
                  options={graphOptions2}
                  containerProps={{ style: { height: "100%" } }}
                />
              ) : (
                <NoDataTableSvg />
              )}
            </div>
            {/* {selectedSources.length == 1 && selectedSources[0].sourceId ? (
              <div className="flex column mt-2 p-1" style={{ width: "200px" }}>
                <div className="flex justify-between">
                  <div className="flex column">
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Source Name
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Annotations
                    </span>
                  </div>
                  <Switch
                    className="self-center"
                    checked={annotationMode}
                    onChange={(checked) => {
                      setAnnotationMode(checked);
                    }}
                  />
                </div>
                <div
                  className="mb-2"
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#E6E6E6",
                  }}
                />
                <span className="mb-1" style={{ textAlign: "justify" }}>
                  To create an annotation, select a point on the graph inside
                  the red band.
                </span>
                {annotationsList.slice(0, 3).map((annotation) => {
                  return <span>{annotation.title}</span>;
                })}
                <span
                  style={{
                    textDecoration: "underline",
                    color: "#078CB3",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowAnnotationListModal(true);
                  }}
                >
                  View all annotations
                </span>
              </div>
            ) : (
              <div
                className="flex column mt-2  p-1"
                style={{ width: "200px", backgroundColor: "#ebebeb" }}
              >
                <div
                  className="flex justify-between"
                  style={{ opacity: "20%" }}
                >
                  <div className="flex column">
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Source Name
                    </span>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      Annotations
                    </span>
                  </div>
                  <Switch disabled defaultChecked={false} />
                </div>
                <div
                  className="mb-2"
                  style={{
                    width: "100%",
                    height: "2px",
                    backgroundColor: "#E6E6E6",
                  }}
                />
                <span
                  className="mb-1"
                  style={{ textAlign: "justify", fontWeight: "bold" }}
                >
                  To enter annotation mode, select only a source that is not
                  recommended.
                </span>
              </div>
            )} */}
          </div>
          <Divider />
          <div className="flex column">
            <div className="flex items-center" style={{ marginBottom: "1rem" }}>
              <span
                className="mr-2"
                style={{
                  color: "#023E65",
                  fontWeight: "bold",
                  textTransform: "uppercase",
                }}
              >
                Current readings
              </span>
              <Input
                placeholder="Search Parameters"
                prefix={<SearchOutlined />}
                style={{ width: "205px" }}
                onChange={(e) => {
                  setQueryParams(e.target.value);
                }}
              />
            </div>
            <div>
              <Table
                columns={columnsExpanded}
                dataSource={paramsToShowInTable()}
                expandable={{
                  expandedRowRender,
                  expandedRowKeys,
                  showExpandColumn: false,
                }}
                style={{ overflowX: "auto" }}
                pagination={false}
                locale={{
                  emptyText: (
                    <div className="flex column">
                      <span>
                        No data: select some data to turn on the light
                      </span>
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="ADD DATA ANNOTATIONS"
        open={showAnnotationModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowAnnotationModal(false);
        }}
      >
        <div className="flex column">
          {isCreationMode ? (
            <>
              <span>Title</span>
              <Input
                className="mb-1 w-full"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              {dates && sourceToAnnotation && (
                <>
                  <span style={{ color: "#858484", fontSize: "10px" }}>
                    Current Data Quality:{" "}
                    {/* sourceId de linea 1610 esta fallando en sourceToAnnotation */}
                    {checkQualityForNewAnnotation(
                      annotationsList.filter(
                        (annotation) =>
                          annotation.sourceId == sourceToAnnotation.sourceId
                      ),
                      dates[0],
                      dates[1]
                    )}
                  </span>
                </>
              )}
              {/* {annotationsList.length == 1 && (
                <span style={{ color: "#858484", fontSize: "10px" }}>
                  Existing Data Quality: {annotationsList[0].quality}
                </span>
              )}
              {annotationsList.length > 1 && (
                <>
                  <span style={{ color: "#858484", fontSize: "10px" }}>
                    Minimum value:{" "}
                    {getRangeString(annotationsList.map((a) => a.quality))[0]}
                  </span>
                  <span style={{ color: "#858484", fontSize: "10px" }}>
                    Maximum value :{" "}
                    {getRangeString(annotationsList.map((a) => a.quality))[1]}
                  </span>
                </>
              )} */}
              <span>New quality value</span>
              <InputNumber
                className="mb-1 w-full"
                min={0}
                max={10}
                value={quality}
                onChange={(val) => {
                  setQuality(val);
                }}
              />
              <span>Comment</span>
              <Input.TextArea
                className="mb-1"
                value={comment}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
              <div className="flex self-center mt-2">
                <Button
                  style={{
                    width: "85px",
                    height: "40px",
                    backgroundColor: "#00203C",
                    color: "white",
                  }}
                  className="mr-4"
                  onClick={() => {
                    handleSaveAnnotation();
                    setQuality(0);
                    setComment("");
                    setTitle("");
                    setShowAnnotationModal(false);
                    setSourceToAnnotation(null);
                  }}
                >
                  Accept
                </Button>
                <Button
                  style={{ width: "85px", height: "40px" }}
                  onClick={() => {
                    setShowAnnotationModal(false);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              <span>Comment</span>
              <Input.TextArea
                className="mb-1"
                value={comment}
                disabled={true}
              />
            </>
          )}
        </div>
      </Modal>
      <Modal
        title="VIEW ALL ANNOTATIONS"
        open={showAnnotationListModal}
        footer={[]}
        closable={true}
        onCancel={() => {
          setShowAnnotationListModal(false);
          setAnnotationListToShow([]);
        }}
      >
        <div className="flex column" style={{ overflow: "auto" }}>
          <Table
            columns={columnsAnnotationsModal}
            dataSource={annotationListToShow}
            locale={{
              emptyText: (
                <div className="flex column">
                  <span>No data: Create some annotation</span>
                </div>
              ),
            }}
          />
        </div>
      </Modal>
    </>
  );
}

// function mapState(state) {
//   return {
//     readingsList: state.readingsResumes.readings,
//     resumeList: state.readingsResumes.resumes,
//   };
// }

// export default connect(mapState)(ExpandedParamsCard);

import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: "b2c_1_login",
        forgotPassword: "b2c_1_reset",
        editProfile: "b2c_1_edit_profile",
    },
    authorities: {
        signUpSignIn: {
            authority:
                process.env.REACT_APP_AZUREAD_DOMAIN + "/B2C_1_Login",
        },
        forgotPassword: {
            authority:
                process.env.REACT_APP_AZUREAD_DOMAIN + "/B2C_1_Reset",
        },
        editProfile: {
            authority:
                process.env.REACT_APP_AZUREAD_DOMAIN + "/B2C_1_Edit_Profile",
        },
    },
    authorityDomain: "hydrodigitalaaddev.b2clogin.com",
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_AZUREAD_CLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Use a sign-up/sign-in user-flow as a default authority
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: process.env.REACT_APP_REDIRECT_URI, // Points to window.location.origin. You must register this URI on Azure Portal/App Registration.
        postLogoutRedirectUri: "https://cdnhydrodigital.azureedge.net/logout", // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        return;
                    case LogLevel.Warning:
                        return;
                }
            },
        },
    },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: [process.env.REACT_APP_AZUREAD_CLIENTID],
    // scopes: ["openid", "profile", "User.Read"],
    // scopes: ["https://graph.windows.net/User.Read", "openid", "profile"],
    // scopes: [
    //     "openid",
    //     "https://hydrodigitaldev.b2clogin.com/hydrodigitaldev.onmicrosoft.com/api/scope.read",
    // ],
};

/**
 * An optional silentRequest object can be used to achieve silent SSO
 * between applications by providing a "login_hint" property.
 */

export const silentRequest = {
    scopes: ["user.read"],
    // account: accounts[0],
    // scopes: ["openid", "profile", "User.Read"],
    // loginHint: preferred_username,
    // extraQueryParameters: { domain_hint: "organizations" },
    // loginHint: "example@domain.net",
};


export const AuthConfigDev= { b2cPolicies, msalConfig, loginRequest, silentRequest };
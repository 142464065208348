import { AuthConfigDev } from "./statics/constants/authConfigDev";
import { AuthConfigProd } from "./statics/constants/authConfigProd";
let b2cPolicies,
  msalConfig,
  loginRequest,
  silentRequest = null;
console.log("process.env.REACT_APP_ENV", process.env.REACT_APP_ENV);
b2cPolicies =
  process.env.REACT_APP_ENV === "prod"
    ? AuthConfigProd.b2cPolicies
    : AuthConfigDev.b2cPolicies;
msalConfig =
  process.env.REACT_APP_ENV === "prod"
    ? AuthConfigProd.msalConfig
    : AuthConfigDev.msalConfig;
loginRequest =
  process.env.REACT_APP_ENV === "prod"
    ? AuthConfigProd.loginRequest
    : AuthConfigDev.loginRequest;
silentRequest =
  process.env.REACT_APP_ENV === "prod"
    ? AuthConfigProd.silentRequest
    : AuthConfigDev.silentRequest;
export { b2cPolicies, msalConfig, loginRequest, silentRequest };
import axios from "axios";
import { store, msalInstance } from "../index";
// import { useDispatch } from "react-redux";
import { setShowLoader } from "../redux/loaderDuck";
import { showErrorModal } from "../redux/modalAlertDuck";

let apiController = new AbortController();
const apiNl = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


apiNl.interceptors.request.use((config) => {
  const account = msalInstance.getAllAccounts()[0];
  console.log("Refresh token...") 
  return msalInstance.acquireTokenSilent({
    scopes: ["7d611a8f-71d3-4bb5-8254-6559b1769661"],
    account: account,
  }).then((response) => {
    const accessToken = response.accessToken;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config; 
  }).catch((error) => {
    console.error('Error acquiring token:', error);
    return Promise.reject(error); 
  });
});

apiNl.interceptors.response.use(
  (response) => {
    //End the response
    return response;
  },
  (error) => {
    if (error.code !== "ERR_CANCELED") {
      store.dispatch(
        showErrorModal(error.message, error.response?.data?.error)
      );
    }
    //Error managment
    return Promise.reject(error);
  }
);

const restartApiCotroller = () => {
  apiController = new AbortController();
};

export { apiNl, apiController, restartApiCotroller };

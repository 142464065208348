import axios from "axios";
// constants
let initialData = {
  fetching: false,
  array: {},
  current: {},
};

let URL = process.env.REACT_APP_BASE_URL + "/maps/";

let GET_MAP = "GET_MAP";
let GET_MAP_SUCCESS = "GET_MAP_SUCCESS";
let GET_MAP_ERROR = "GET_MAP_ERROR";

// reducer
export default function reducer(state = initialData, action) {
  switch (action.type) {
    case GET_MAP:
      return { ...state, fetching: true };
    case GET_MAP_SUCCESS:
      return { ...state, array: action.payload, fetching: false };
    case GET_MAP_ERROR:
      return { ...state, fetching: false, error: action.payload };
    default:
      return state;
  }
}
// actions (tunks)

export let getMapsAction = (id) => (dispatch, getState) => {
  if (!id) return;
  dispatch({
    type: GET_MAP,
  });

  return Promise.resolve(fetcher(id))
    .then((res) => {
      dispatch({
        type: GET_MAP_SUCCESS,
        payload: res,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: GET_MAP_ERROR,
        payload: err?.response?.message || "Error fetching map",
      });
    });
};

const fetcher = async (id) => {
  let access_token = localStorage.getItem("accessToken");
  let response = await fetch(`${URL}${id}/data`, {
    headers: {
      Authorization: `Bearer ${access_token}`,
    },
  });
  return await response.json();
};
